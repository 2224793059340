<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
      <h2 class="brand-text text-primary ml-1">
        TINGEE
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Không tìm thấy trang 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Lỗi! 😖 Trang bạn yêu cầu không tồn tại.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          Quay về trang chủ
        </b-button>

        <!-- image -->
        <b-img
          fluid
          src="@/assets/images/pages/error.svg"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue';
import Logo from '@/layouts/components/Logo.vue';

export default {
  components: {
    Logo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
